import { ReactNode } from 'react';
import { ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';
import { MaskIcon, SimpleTooltip } from '@front/ui';

const styles = {
  button: {
    width: '100%',
    opacity: 0.64,
    '& img': {
      maxWidth: '100%',
      verticalAlign: 'middle',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      width: 4,
      height: 0,
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      bgcolor: 'text.primary',
      transitionDuration: '0.3s',
    },
  },
  buttonInner: {
    width: 32,
    height: 32,
  },
  buttonHovered: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        opacity: 1,
        '&:before': {
          height: 16,
        },
        '& .menu-icon:not(.active)': {
          bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        },
      },
    },
  },
  buttonActive: {
    opacity: 1,
    '&:before': {
      height: 32,
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '&:before': {
          height: 32,
        },
      },
    },
  },
  buttonIcon: {
    width: '100%',
    height: '100%',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    transitionDuration: '0.3s',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  buttonIconActive: {
    bgcolor: 'text.primary',
    color: 'background.darker',
  },
  tooltip: {
    '&[data-popper-placement*="right"] .MuiTooltip-tooltip': {
      ml: '-4px !important',
      p: 1,
    },
  },
  contentWrapper: {
    width: '100%',
    position: 'relative',
  },
  notificationDot: {
    position: 'absolute',
    top: -7,
    right: 3,
    color: 'error.dark',
    pointerEvents: 'none',
  },
};

type NavButtonProps = {
  children: ReactNode;
  tooltip?: string;
  disabled?: boolean;
  mask?: boolean;
  active?: boolean;
  hasNotification?: boolean;
  onClick?: () => void;
  sx?: ButtonProps['sx'];
};

export default function NavButton({
  children,
  tooltip,
  active = false,
  disabled = false,
  mask = true,
  hasNotification,
  onClick,
  sx,
}: NavButtonProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const content = (
    <ButtonBase
      sx={[
        styles.button,
        !disabled && styles.buttonHovered,
        active && styles.buttonActive,
        ...sxProps,
      ]}
      disableRipple
      onClick={disabled ? undefined : onClick}
    >
      <Box sx={styles.buttonInner} className="menu-button-inner">
        {mask ? (
          <MaskIcon
            sx={[styles.buttonIcon, active && styles.buttonIconActive]}
            className={`menu-icon ${active ? 'active' : ''}`}
          >
            {children}
          </MaskIcon>
        ) : (
          children
        )}
      </Box>
      {hasNotification && (
        <Box sx={styles.notificationDot}>
          <ChatNotificationDotIcon />
        </Box>
      )}
    </ButtonBase>
  );

  if (mdUp && tooltip) {
    return (
      <SimpleTooltip
        title={tooltip}
        placement="right"
        slotProps={{
          popper: {
            sx: styles.tooltip,
          },
        }}
      >
        <Box sx={styles.contentWrapper}>{content}</Box>
      </SimpleTooltip>
    );
  }
  return content;
}
