import React from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Chip, Theme } from '@mui/material';
import useProductCount from '@app/web/src/hooks/utils/useProductCount';
import {
  ActionCloseSmall as ActionCloseSmallIcon,
  NFTFavoriteSolid as NFTFavoriteSolidIcon,
  ProfileNFTs as ProfileNFTsIcon,
  TestClockSolid as TestClockSolidIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import { MenuComps } from '@lib/web/ui';

const getCountNumber = (number?: number) => {
  if (!number) return '0';
  if (number > 99) return '99+';
  return number;
};

const styles = {
  count: {
    height: '22px',
    ml: 1,
    color: 'text.primary',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    '& span': {
      px: 1,
    },
  },
};

export default function MarketplaceMenu() {
  const { t } = useTranslation();
  const { data: productCount } = useProductCount();

  return (
    <MenuComps title={t('menu.Avatar Marketplace')}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<TextEditorCheckIcon />}
          href="/marketplace/available"
        >
          {t('menu.Available')}
          <Chip
            label={getCountNumber(productCount.available)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ActionCloseSmallIcon />}
          href="/marketplace/unavailable"
        >
          {t('menu.Unavailable')}
          <Chip
            label={getCountNumber(productCount.unavailable)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestClockSolidIcon />}
          href="/marketplace/coming-soon"
        >
          {t('menu.Coming Soon')}
          <Chip
            label={getCountNumber(productCount.comingSoon)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<NFTFavoriteSolidIcon />}
          href="/marketplace/my-favorite"
        >
          {t('menu.My Favorite')}
          <Chip
            label={getCountNumber(productCount.favorite)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ProfileNFTsIcon />}
          href="/marketplace/my-avatars"
        >
          {t('menu.My Avatars')}
          <Chip
            label={getCountNumber(productCount.myAvatars)}
            size="small"
            sx={styles.count}
          />
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}
