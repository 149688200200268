import { useTranslation } from 'react-i18next';
import { alpha, Theme, Typography } from '@mui/material';
import useProductCount from '@app/web/src/hooks/utils/useProductCount';
import {
  ActionCloseSmall as ActionCloseSmallIcon,
  NFTFavoriteSolid as NFTFavoriteSolidIcon,
  ProfileNFTs as ProfileNFTsIcon,
  TestClockSolid as TestClockSolidIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import { MenuComps } from '@lib/web/ui';

const getCountNumber = (number?: number) => {
  if (!number) return '0';
  if (number > 99) return '99+';
  return number;
};

const styles = {
  count: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    px: 1,
    py: '2px',
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '16px',
  },
};

export default function AvatarsMenu() {
  const { t } = useTranslation();
  const { data: productCount } = useProductCount();

  return (
    <MenuComps title={t('menu.Avatar Marketplace', 'Avatar Marketplace')}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<TextEditorCheckIcon />}
          href="/marketplace/available"
          suffix={
            <Typography variant="caption" sx={styles.count}>
              {getCountNumber(productCount.available)}
            </Typography>
          }
        >
          {t('menu.Available')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ActionCloseSmallIcon />}
          href="/marketplace/unavailable"
          suffix={
            <Typography variant="caption" sx={styles.count}>
              {getCountNumber(productCount.unavailable)}
            </Typography>
          }
        >
          {t('menu.Unavailable')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestClockSolidIcon />}
          href="/marketplace/coming-soon"
          suffix={
            <Typography variant="caption" sx={styles.count}>
              {getCountNumber(productCount.comingSoon)}
            </Typography>
          }
        >
          {t('menu.Coming Soon')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<NFTFavoriteSolidIcon />}
          href="/marketplace/my-favorite"
          suffix={
            <Typography variant="caption" sx={styles.count}>
              {getCountNumber(productCount.favorite)}
            </Typography>
          }
        >
          {t('menu.My Favorite')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ProfileNFTsIcon />}
          href="/marketplace/my-avatars"
          suffix={
            <Typography variant="caption" sx={styles.count}>
              {getCountNumber(productCount.myAvatars)}
            </Typography>
          }
        >
          {t('menu.My Avatars')}
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}
