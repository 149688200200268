import React, { useMemo } from 'react';
import Router from 'next/router';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useMainLayout from '@app/web/src/layouts/MainLayout/hooks/useMainLayout';
import useMenuCurrentClub from '@app/web/src/layouts/MainLayout/hooks/useMenuCurrentClub';
import { getClubIconPath } from '@app/web/src/utils/clubs';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';
import {
  MaskIcon,
  SimpleTooltip,
  SquareAvatar,
  useBaseRightPanel,
  useTruncated,
} from '@front/ui';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

const styles = {
  button: {
    position: 'relative',
    width: '100%',
    height: '61px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 1,
    px: 1.5,
    py: 1,
    '& img': {
      maxWidth: '100%',
      verticalAlign: 'middle',
    },
  },
  buttonIcon: {
    transitionDuration: '0.3s',
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  buttonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    typography: 'body1',
    fontWeight: 700,
  },
  buttonTag: {
    typography: 'body1',
    color: 'text.primary',
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderRadius: '26px',
    px: '8px',
    py: '1px',
    flexShrink: 0,
  },
  buttonHovered: {
    '@media (hover: hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  buttonActive: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  notificationDot: {
    position: 'absolute',
    top: 1,
    right: 4,
    color: 'error.dark',
    pointerEvents: 'none',
  },
};

interface ClubGroupClubButtonProps {
  clubGroupId: string;
  clubNotification: ClubUnreadNotification;
}

export default function ClubGroupClubButton({
  clubNotification,
}: ClubGroupClubButtonProps) {
  const validIconPath =
    clubNotification.iconPath || getClubIconPath(clubNotification.name || '');
  const { seeClub, clearMenu } = useMainLayout();
  const { club: currentClub } = useMenuCurrentClub();
  const { rightPanelOpened, closeRightPanel } = useBaseRightPanel();
  const { unreadChannels } = useThread();

  const { containerRef, isTruncated } = useTruncated();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const active = currentClub?.clubSlug === clubNotification.slug;

  const hasUnreadChannelsForThisClub = useMemo(
    () =>
      unreadChannels.some(
        (channel) => channel.data?.clubId === clubNotification.id
      ),
    [unreadChannels, clubNotification.id]
  );

  const isJoined = clubNotification.isSubscribed;
  const handleClick = async () => {
    if (active) return;

    if (mdUp && rightPanelOpened) closeRightPanel();

    if (isJoined) {
      await Router.push(`/club/${clubNotification.slug}/start`);
      clearMenu();
      seeClub(clubNotification.slug);
    } else {
      clearMenu();
      void Router.push(`/club/${clubNotification.slug}`, undefined, {
        shallow: true,
      });
    }
  };

  return (
    <ButtonBase
      sx={[styles.button, styles.buttonHovered, active && styles.buttonActive]}
      disableRipple
      onClick={handleClick}
    >
      <MaskIcon sx={styles.buttonIcon}>
        <SquareAvatar src={validIconPath} size={32}>
          {clubNotification.name}
        </SquareAvatar>
      </MaskIcon>
      {isTruncated ? (
        <SimpleTooltip title={clubNotification.name}>
          <Typography sx={styles.buttonText} ref={containerRef}>
            {clubNotification.name}
          </Typography>
        </SimpleTooltip>
      ) : (
        <Typography sx={styles.buttonText} ref={containerRef}>
          {clubNotification.name}
        </Typography>
      )}
      {!clubNotification.isSubscribed && (
        <Box sx={styles.buttonTag}>Not Joined</Box>
      )}
      {(clubNotification.count > 0 || hasUnreadChannelsForThisClub) && (
        <Box sx={styles.notificationDot}>
          <ChatNotificationDotIcon />
        </Box>
      )}
    </ButtonBase>
  );
}
