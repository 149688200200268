import { forwardRef, MouseEvent, ReactNode } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { alpha, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';
import { MaskIcon, SimpleTooltip } from '@front/ui';

const styles = {
  contentWrapper: {
    width: '100%',
    position: 'relative',
  },
  title: {
    fontSize: 11,
    opacity: 0.75,
    lineHeight: '12px',
  },
  titleActive: {
    opacity: 1,
  },
  button: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    '& img': {
      maxWidth: '100%',
      verticalAlign: 'middle',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      width: 4,
      height: 0,
      left: 0,
      top: 'calc(50% - 8px)',
      transform: 'translateY(-50%)',
      bgcolor: 'text.primary',
      transitionDuration: '0.3s',
    },
  },
  buttonNoTitle: {
    '&:before': {
      top: 'calc(50%)',
    },
  },
  buttonInner: {
    width: 32,
    height: 32,
  },
  buttonHovered: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        opacity: 1,
        '&:before': {
          height: 12,
        },
        '& .menu-icon:not(.active)': {
          bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
        },
      },
    },
  },
  buttonActive: {
    opacity: 1,
    '&:before': {
      height: 32,
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '&:before': {
          height: 32,
        },
      },
    },
  },
  buttonIcon: {
    width: '100%',
    height: '100%',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    transitionDuration: '0.3s',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  buttonIconActive: {
    bgcolor: 'text.primary',
    color: 'background.darker',
  },
  tooltip: {
    '&[data-popper-placement*="right"] .MuiTooltip-tooltip': {
      ml: '-4px !important',
      mt: '-14px !important',
    },
  },
  tooltipNoTitle: {
    '&[data-popper-placement*="right"] .MuiTooltip-tooltip': {
      mt: '0 !important',
    },
  },
  tooltipHidden: {
    display: 'none',
  },
  notificationDot: {
    position: 'absolute',
    top: -8,
    right: 3,
    color: 'error.dark',
    pointerEvents: 'none',
  },
};

type SubNavButtonProps = {
  title?: string;
  children: ReactNode;
  tooltip?: string;
  hideTooltip?: boolean;
  disabled?: boolean;
  mask?: boolean;
  active?: boolean;
  sx?: ButtonBaseProps['sx'];
  onClick?: (ev: MouseEvent) => void;
  hasNotification?: boolean;
};

const SubNavButton = forwardRef<HTMLButtonElement, SubNavButtonProps>(
  (
    {
      sx,
      title,
      children,
      tooltip,
      hideTooltip,
      mask = true,
      active = false,
      disabled = false,
      onClick,
      hasNotification,
    },
    ref
  ) => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const handleClick = (ev: MouseEvent) => {
      if (!disabled) {
        onClick?.(ev);
      }
    };
    const sxProps = Array.isArray(sx) ? sx : [sx];

    const content = (
      <ButtonBase
        sx={[
          styles.button,
          !title && styles.buttonNoTitle,
          !disabled && styles.buttonHovered,
          active && styles.buttonActive,
          ...sxProps,
        ]}
        disableRipple
        onClick={handleClick}
        ref={ref}
      >
        <Box sx={styles.buttonInner}>
          {mask ? (
            <MaskIcon
              sx={[styles.buttonIcon, active && styles.buttonIconActive]}
              className={`menu-icon ${active ? 'active' : ''}`}
            >
              {children}
            </MaskIcon>
          ) : (
            children
          )}
        </Box>
        {title && (
          <Typography sx={[styles.title, active && styles.titleActive]}>
            {title}
          </Typography>
        )}
        {hasNotification && (
          <Box sx={styles.notificationDot}>
            <ChatNotificationDotIcon />
          </Box>
        )}
      </ButtonBase>
    );

    if (mdUp && tooltip) {
      return (
        <SimpleTooltip
          title={tooltip}
          placement="right"
          slotProps={{
            popper: {
              sx: [
                styles.tooltip,
                !title && styles.tooltipNoTitle,
                !!hideTooltip && styles.tooltipHidden,
              ],
            },
          }}
        >
          <Box sx={styles.contentWrapper}>{content}</Box>
        </SimpleTooltip>
      );
    }
    return content;
  }
);

SubNavButton.displayName = 'SubNavButton';
export default SubNavButton;
