import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import useMenuCurrentClubSlug from '@app/web/src/layouts/MainLayout/hooks/useMenuCurrentClubSlug';
import {
  ActionMore as ActionMoreIcon,
  EditorRename as EditorRenameIcon,
  OtherCopy as OtherCopyIcon,
  OtherDelete as OtherDeleteIcon,
} from '@front/icon';
import { ResponsiveMenu, TipButton } from '@front/ui';
import { ThreadViewType } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useUpdateClubThreadView } from '@lib/web/thread/hooks/view/useUpdateClubThreadView';
import { getDefaultThreadViewUsersId } from '@lib/web/thread/hooks/view/utils';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';

type Options = {
  name: string;
  Icon: (params: { [x: string]: unknown }) => JSX.Element;
  handler: () => void | Promise<void>;
}[];

export type ClubMenuThreadViewOptionProps = {
  isHovered: boolean;
  resetHovered: () => void;
  clubId?: string;
  view: GetThreadViewRes;
};

export default function ClubMenuThreadViewOption({
  isHovered,
  resetHovered,
  clubId,
  view,
}: ClubMenuThreadViewOptionProps): JSX.Element {
  const { t } = useTranslation('thread');
  const clubSlug = useMenuCurrentClubSlug();
  const toggleButtonRef = useRef<HTMLButtonElement | null>(null);
  const [openOption, setOpenOption] = useState(false);

  const { views, reloadViews: reloadClubThreadViews } = useClubThreadViews({
    clubId,
  });
  const {
    createClubThreadView,
    duplicateClubThreadView,
    removeClubThreadView,
  } = useUpdateClubThreadView({ clubId });
  const { getThreadViewDetail } = useThreadViewDetails();

  const {
    viewId: threadViewId,
    viewName,
    viewType,
    threadViewUserId,
  } = getThreadViewDetail(view);

  const { threadTitleTextRef } = useThread();

  const isSharedClubThreadView = useMemo(() => {
    return getDefaultThreadViewUsersId(clubId) === threadViewUserId;
  }, [clubId, threadViewUserId]);

  const showOptionMenu = useMemo(() => {
    return viewType !== ThreadViewType.ClubAllThread && isHovered;
  }, [isHovered, viewType]);

  const genCustomName = useCallback((): string => {
    const allThreadIndexList = views
      .filter((item) => {
        return (
          item.type === ThreadViewType.Club &&
          item.threadViewUsers[0].customName?.includes(t('menu.All threads'))
        );
      })
      .map((item) => {
        return Number(item.threadViewUsers[0].customName?.split(' ').at(-1));
      });
    const maxAllThreadIndex = Math.max(0, ...allThreadIndexList);

    return `${t('menu.All threads')} ${maxAllThreadIndex + 1}`;
  }, [t, views]);

  const options: Options = [
    ...(viewType === ThreadViewType.Club
      ? [
          {
            name: t('menu.Rename'),
            Icon: EditorRenameIcon,
            handler: () => {
              setOpenOption(false);

              // Zero delay to focus DOM element
              setTimeout(() => {
                threadTitleTextRef.current?.focus();
              });
            },
          },
        ]
      : []),
    {
      name: t('menu.Duplicate'),
      Icon: OtherCopyIcon,
      handler: async () => {
        setOpenOption(false);

        let viewId: string | undefined;
        if (!isSharedClubThreadView) {
          const name = `${viewName} 1`;
          const { newViewId } = await duplicateClubThreadView({
            type: viewType,
            threadViewId,
            threadViewUserId,
            name,
          });
          viewId = newViewId;
        } else {
          const customName = genCustomName();
          const { newViewId } = await createClubThreadView({ customName });
          viewId = newViewId;
        }
        await Router.push(`/club/${clubSlug}/thread/${viewId}`);
        await reloadClubThreadViews();
      },
    },
    ...(viewType === ThreadViewType.Club
      ? [
          {
            name: t('menu.Remove'),
            Icon: OtherDeleteIcon,
            handler: async () => {
              setOpenOption(false);
              await removeClubThreadView({ threadViewUserId });
              await reloadClubThreadViews();
            },
          },
        ]
      : []),
  ];

  const handleCloseOption = useCallback((): void => {
    setOpenOption(false);
    resetHovered();
  }, [resetHovered]);

  const handleOpenOption = useCallback((): void => {
    setOpenOption(true);
  }, []);

  return (
    <>
      {showOptionMenu && (
        <TipButton
          ref={toggleButtonRef}
          customSize={24}
          onClick={handleOpenOption}
          title="More"
        >
          <ActionMoreIcon />
        </TipButton>
      )}
      <ResponsiveMenu
        open={openOption}
        onClose={handleCloseOption}
        menuProps={{
          anchorEl: toggleButtonRef.current,
          anchorOrigin: {
            vertical: 'center',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'center',
            horizontal: 'left',
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.name} onClick={option.handler}>
              <ListItemIcon>
                <option.Icon width={16} height={16} />
              </ListItemIcon>
              <ListItemText>{option.name}</ListItemText>
            </MenuItem>
          );
        })}
      </ResponsiveMenu>
    </>
  );
}
