import { useDateFormat } from '@front/helper';
import { currencyFormat } from '@front/ui';
import { ListTooltipLayoutConfig } from '@lib/ia/src/layouts/ListTooltipLayout/types';
import { ClubOfficialType, ClubPrivacy } from '@lib/web/apis';
import { getClubIconPath } from '@lib/web/utils';

import { JoinedState } from '../components/NavButtons/NavSimilarClubs';

function getDescription(
  club: GetIaSimilarClubCardCenterDefaultViewRes,
  currentState: JoinedState
) {
  const isPrivate = club.privacy === ClubPrivacy.Private;
  if (isPrivate && currentState === 'invited') {
    return 'You have been invited to join this private club. Click to accept.';
  }
  if (isPrivate && currentState === 'requested') {
    return 'Request to join this club has been sent. Click to cancel request.';
  }
  if (isPrivate) {
    return 'Click to request to join this private club.';
  }
  return 'Click to join this club.';
}

export default function useSimilarClubMenuTooltip(
  club: GetIaSimilarClubCardCenterDefaultViewRes,
  currentState: JoinedState
): ListTooltipLayoutConfig {
  const { dateFormat } = useDateFormat();

  const validAppIconPath = club.clubLogo || getClubIconPath(club.clubName);
  const isOfficial = club.officialType === ClubOfficialType.Official;
  const isPrivate = club.privacy === ClubPrivacy.Private;

  return {
    layout: 'list-tooltip-layout',
    title: {
      content: club.clubName,
      prefix: {
        type: 'avatar',
        avatarUrl: validAppIconPath,
        avatarText: club.clubName,
      },
      suffix: isOfficial
        ? {
            type: 'icon',
            name: 'ProfileOfficial',
          }
        : undefined,
    },
    description: {
      content: getDescription(club, currentState),
    },
    properties: [
      {
        name: {
          icon: 'PropertyTypePerson',
          text: 'Club Creator',
        },
        value: {
          type: 'avatarText',
          avatarText: club.clubCreator.clubCreatorName,
          avatarUrl:
            club.clubCreator.clubCreatorName !== 'Aha'
              ? club.clubCreator.clubCreatorAvatar
              : '/logo.png',
        },
      },
      {
        name: {
          icon: 'PrivacyFriends',
          text: 'Members',
        },
        value: {
          type: 'text',
          text: currencyFormat(club.clubMemberCount),
        },
      },
      {
        name: {
          icon: 'UserOnline',
          text: 'Online',
          sxProps: {
            '& svg path': {
              fill: '#00C398',
            },
          },
        },
        value: {
          type: 'text',
          text: currencyFormat(club.onlineMemberCount),
        },
      },
      {
        name: {
          icon: 'ChatQuestionList',
          text: 'Questions',
        },
        value: {
          type: 'text',
          text: currencyFormat(club.totalQuestionCount),
        },
      },
      {
        name: {
          icon: 'OtherCalendarDay',
          text: 'Club Created',
        },
        value: {
          type: 'text',
          text: dateFormat(club.createdAt),
        },
      },
    ],
    tags: [
      isPrivate && {
        color: 'blue',
        text: 'Private',
        icon: 'PrivacyPrivate',
      },
    ],
  };
}
