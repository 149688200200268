import { useDeferredValue } from 'react';
import combineSort from '@lib/ia/src/utils/combineSort';
import { ClubGroupViewSlug, useIaClubGroups } from '@lib/web/apis';
import { isEqual } from 'lodash';

import useIaFilterValues from '../../hooks/utils/useIaFilterValues';
import useIaQueryHelperValues from '../../hooks/utils/useIaQueryHelperValues';
import { useIaSearchValues } from '../../hooks/utils/useIaSearchValues';
import useIaSortValues from '../../hooks/utils/useIaSortValues';

export const useDiscoverClubGroups = () => {
  const { searchQuery } = useIaFilterValues();
  const { debouncedSearch } = useIaSearchValues();
  const { sortQuery, rawQueryParams } = useIaSortValues();
  const { isInitializing } = useIaQueryHelperValues();

  const filterDeferred = useDeferredValue(searchQuery);
  const isFilterStale = !isEqual(searchQuery, filterDeferred);

  const clubGroupsData = useIaClubGroups(
    {
      viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
      ...filterDeferred,
      ...combineSort(sortQuery),
      keywordFuzzy: debouncedSearch,
      rawQueryParams,
      limit: 21, // there is 1 'create card' on the top, so instead of 20, we use 21 to make loading looks better
    },
    {
      enable: !isInitializing,
    }
  );
  const { isLoadingInitialData, error } = clubGroupsData;

  return {
    clubGroupsData,
    isLoading: isFilterStale || isLoadingInitialData,
    isError: !!error,
    isInitializing,
    isLoadingMore: clubGroupsData.isLoadingMore,
    isLoadingInitialData: clubGroupsData.isLoadingInitialData,
    mutate: clubGroupsData.mutate,
    dataset: clubGroupsData.dataset,
    totalCount: clubGroupsData.totalCount,
    error: clubGroupsData.error,
  };
};
