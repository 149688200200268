import React from 'react';
import { useTranslation } from 'react-i18next';
import EmojiText from '@app/web/src/components/EmojiText';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import ClubGroupMenuToolComponent from '@app/web/src/layouts/MainLayout/components/Menu/components/ClubGroupMenu/ClubGroupMenuToolComponent';
import { useBaseLeftPanel } from '@front/ui';
import { MenuComps } from '@lib/web/ui';

import useMainLayout from '../../hooks/useMainLayout';
import { useMenuCurrentClubGroup } from '../../hooks/useMenuCurrentClubGroup';

import ClubGroupClubButton from './components/ClubGroupMenu/ClubGroupClubButton';

const styles = {
  empty: {
    px: '12px',
  },
};

export default function ClubGroupMenu() {
  const { t } = useTranslation('club');
  const { clubGroupNotification } = useMenuCurrentClubGroup();
  const { disableLeftPanel } = useBaseLeftPanel();
  const { menuTarget, clearTemporaryMenu } = useMainLayout();
  const handleCollapse = () => {
    if (menuTarget) clearTemporaryMenu();
    else disableLeftPanel();
  };

  usePrefetchPages(['/club/[slug]/start', '/club/[slug]']);

  if (!clubGroupNotification) return null;

  return (
    <MenuComps
      title={clubGroupNotification.name}
      toolComponent={
        <ClubGroupMenuToolComponent
          clubGroupNotification={clubGroupNotification}
        />
      }
      onCollapse={handleCollapse}
    >
      <MenuComps.Section>
        <MenuComps.SubTitle
          title={t('## clubs', { count: clubGroupNotification.clubs.length })}
        />
        {clubGroupNotification.clubs.map((clubNotification) => (
          <ClubGroupClubButton
            key={clubNotification.slug}
            clubGroupId={clubGroupNotification.id}
            clubNotification={clubNotification}
          />
        ))}
        {clubGroupNotification.clubs.length === 0 && (
          <EmojiText
            text={t('All Clubs in this Club Group has been deleted')}
            sx={styles.empty}
          />
        )}
      </MenuComps.Section>
    </MenuComps>
  );
}
