import {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Router from 'next/router';
import {
  alpha,
  ButtonBase,
  keyframes,
  Theme,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import {
  ActionClose as ActionCloseIcon,
  TestAdd as TestAddIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import { LoadingIcon, MaskIcon, SimpleTooltip, SquareAvatar } from '@front/ui';
import ListTooltipLayout from '@lib/ia/src/layouts/ListTooltipLayout';
import {
  apis,
  ClubJoinedStatus,
  ClubPrivacy,
  SimilarClubViewSlug,
  useIaSimilarClubsList,
} from '@lib/web/apis';
import { useNotifications } from '@lib/web/hooks';
import { call, getClubIconPath } from '@lib/web/utils';

import useCurrentMenu from '../../hooks/useCurrentMenu';
import useMainLayout from '../../hooks/useMainLayout';
import useMenuCurrentClub from '../../hooks/useMenuCurrentClub';
import useSimilarClubMenuTooltip from '../../hooks/useSimilarClubMenuTooltip';

import NavButton from './NavButton';

const opened = (height: number) => keyframes`
  0% {
    height: 0;
    margin-top: 12px;
  }
  100% {
    height: ${height}px;
    margin-top: 12px;
  }
`;

const styles = {
  root: {
    width: '100%',
    display: 'grid',
    position: 'relative',
    scrollMargin: '12px',
  },
  background: {
    position: 'absolute',
    top: '16px',
    left: '50%',
    width: '36px',
    bottom: '16px',
    transform: 'translateX(-50%)',
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '18px',
      left: 0,
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
    '&:before': {
      top: '-18px',
      mask: 'url(/mask-avatar.svg) top center/cover no-repeat',
    },
    '&:after': {
      bottom: '-18px',
      mask: 'url(/mask-avatar.svg) bottom center/cover no-repeat',
    },
  },
  clubs: {
    display: 'grid',
    gap: '12px',
    height: 0,
    overflow: 'hidden',
  },
  clubsOpened: (count: number) => ({
    animation: `${opened(count * 32 + (count - 1) * 12)} 1s ease forwards`,
    animationDelay: '50ms',
  }),
  notJoinedClub: {
    position: 'relative',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .menu-club-not-joined': {
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
  },
  notJoinedClubLogo: {
    opacity: 0.64,
  },
  notJoinedClubAction: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  notJoinedClubActionActive: {
    visibility: 'visible',
    opacity: 1,
  },
  notJoinedClubActionButton: {
    width: '100%',
    height: '100%',
    bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.5),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    '&[data-popper-placement*="right-start"] .MuiTooltip-tooltip': {
      ml: '-4px !important',
    },
    '& .MuiTooltip-tooltip': {
      p: 0,
      borderRadius: 1,
      maxWidth: 'unset',
    },
  },
};

type ClubItem = GetIaSimilarClubCardCenterDefaultViewRes;
export type JoinedState =
  | 'notJoined'
  | 'newlyJoined'
  | 'joined'
  | 'requested'
  | 'invited';
type JoinedMap = Record<string, JoinedState>;

function scrollToCurrentClub(
  el: HTMLDivElement | null,
  similarClubsHeight = 0
) {
  if (!el) return;
  const scrollContainer = el.closest(
    '.simplebar-content-wrapper'
  ) as HTMLDivElement;
  if (!scrollContainer) return;

  // should set this padding to ensure scroll to the current club maximum possible,
  // cause the similar clubs section needs  1000ms to expand completely
  scrollContainer.style.paddingBottom = `${similarClubsHeight}px`;

  const offsetTop = el.offsetTop || 0;
  scrollContainer.scrollTop = offsetTop - 12;

  setTimeout(() => {
    // back to default value, when the similar clubs section is already expanded after 1000ms
    scrollContainer.style.paddingBottom = '0px';
  }, 1500);
}

async function doAction({
  api,
  onSuccess,
  onError,
}: {
  api: Promise<any>;
  onSuccess: () => void;
  onError: () => void;
}) {
  const [, error] = await call(api);
  if (error) {
    onError();
    return;
  }
  onSuccess();
}

type ActionOptions = {
  clubSlug: string;
  onSuccess: () => void;
  onError: () => void;
};

function useSimilarClubActions() {
  return useMemo(() => {
    return {
      join: async ({ clubSlug, onSuccess, onError }: ActionOptions) => {
        await doAction({
          api: apis.club.joinClub(clubSlug),
          onError,
          onSuccess,
        });
      },
      accept: async ({ clubSlug, onSuccess, onError }: ActionOptions) => {
        await doAction({
          api: apis.club.acceptClubInvitation({ clubSlug, isReturn: true }),
          onError,
          onSuccess,
        });
      },
      request: async ({ clubSlug, onSuccess, onError }: ActionOptions) => {
        await doAction({
          api: apis.club.requestJoinClub(clubSlug),
          onError,
          onSuccess,
        });
      },
      cancel: async ({ clubSlug, onSuccess, onError }: ActionOptions) => {
        await doAction({
          api: apis.club.cancelJoinClub(clubSlug),
          onError,
          onSuccess,
        });
      },
    };
  }, []);
}

function getJoinedState(notJoinedClub: ClubItem): JoinedState {
  const isPrivate = notJoinedClub.privacy === ClubPrivacy.Private;
  if (
    isPrivate &&
    notJoinedClub.joinStatus === ClubJoinedStatus.ManagerInvitedPending
  ) {
    return 'invited';
  }
  if (
    isPrivate &&
    notJoinedClub.joinStatus === ClubJoinedStatus.RequestedPending
  ) {
    return 'requested';
  }
  return 'notJoined';
}

type NotJoinedActionProps = {
  state: JoinedState;
  loading?: boolean;
  club: ClubItem;
  onClick: () => void;
};

function NotJoinedAction({
  state,
  loading = false,
  club,
  onClick,
}: NotJoinedActionProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const tooltipConfig = useSimilarClubMenuTooltip(club, state);

  return (
    <SimpleTooltip
      placement="right-start"
      slotProps={{
        popper: {
          sx: styles.tooltip,
        },
      }}
      title={<ListTooltipLayout {...tooltipConfig} />}
    >
      <Box
        sx={[
          styles.notJoinedClubAction,
          ((state !== 'notJoined' && state !== 'requested') || !mdUp) &&
            styles.notJoinedClubActionActive,
        ]}
        className="menu-club-not-joined"
      >
        <ButtonBase
          sx={styles.notJoinedClubActionButton}
          disabled={state !== 'notJoined' && state !== 'requested'}
          onClick={onClick}
        >
          {!loading && (
            <>
              {state === 'notJoined' && <TestAddIcon width={20} height={20} />}
              {state === 'newlyJoined' && (
                <TextEditorCheckIcon width={20} height={20} />
              )}
              {state === 'requested' && (
                <ActionCloseIcon width={20} height={20} />
              )}
            </>
          )}
          {loading && <LoadingIcon />}
        </ButtonBase>
      </Box>
    </SimpleTooltip>
  );
}

type NavSimilarClubsProps = {
  children: ReactNode;
  clubNotification: ClubUnreadNotification;
};

export default function NavSimilarClubs({
  children,
  clubNotification,
}: NavSimilarClubsProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const loaded = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const { seeClub, clearMenu } = useMainLayout();
  const { club: currentClub } = useMenuCurrentClub();
  const { currentMenu } = useCurrentMenu();

  const { dataset, mutate: similarClubsMutate } = useIaSimilarClubsList({
    viewSlug: SimilarClubViewSlug.CardCenterDefault,
    search: `clubSlug:${clubNotification.slug}`,
    searchFields: 'clubSlug:eq',
  });

  const [similarClubs, setSimilarClubs] = useState<ClubItem[]>([]);
  const [joinedStates, setJoinedStates] = useState<JoinedMap>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});

  const { mutate: clubNotificationsMutate } = useNotifications();
  const actions = useSimilarClubActions();

  useEffect(() => {
    if (dataset.length === 0 || loaded.current) return;
    loaded.current = true;

    const notJoinedClubs = dataset.filter(
      (item) =>
        item.joinStatus !== ClubJoinedStatus.Joined &&
        item.joinStatus !== ClubJoinedStatus.RequestedPending
    );
    scrollToCurrentClub(ref.current, notJoinedClubs.length * 44);
    setSimilarClubs(notJoinedClubs);
    const statesMap = notJoinedClubs.reduce(
      (prev, item) => ({ ...prev, [item.clubSlug]: getJoinedState(item) }),
      {}
    );
    setJoinedStates(statesMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset.length]);

  useEffect(() => {
    return () => {
      clubNotificationsMutate();
    };
  }, [clubNotificationsMutate]);

  const handleSeeClub = (club: ClubItem) => {
    if (mdUp) {
      clubNotificationsMutate();
      void Router.push(`/club/${club.clubSlug}/start`);
    }
    clearMenu();
    seeClub(club.clubSlug);
  };

  const updateJoinedState = (clubSlug: string, newState: JoinedState) => {
    setJoinedStates((prev) => ({
      ...prev,
      [clubSlug]: newState,
    }));
  };

  const updateLoading = (clubSlug: string, val: boolean) => {
    setLoading((prev) => ({
      ...prev,
      [clubSlug]: val,
    }));
  };

  const handleJoinClubClick = async (club: ClubItem) => {
    const isPrivate = club.privacy === ClubPrivacy.Private;
    const state = joinedStates[club.clubSlug];

    updateLoading(club.clubSlug, true);

    if (isPrivate && state === 'notJoined') {
      actions.request({
        clubSlug: club.clubSlug,
        onError: () => updateJoinedState(club.clubSlug, state),
        onSuccess: () => {
          similarClubsMutate();
          updateLoading(club.clubSlug, false);
          updateJoinedState(club.clubSlug, 'requested');
        },
      });
      return;
    }

    if (isPrivate && state === 'requested') {
      actions.cancel({
        clubSlug: club.clubSlug,
        onError: () => updateJoinedState(club.clubSlug, state),
        onSuccess: () => {
          similarClubsMutate();
          updateLoading(club.clubSlug, false);
          updateJoinedState(club.clubSlug, 'notJoined');
        },
      });
      return;
    }

    if (isPrivate && state === 'invited') {
      actions.accept({
        clubSlug: club.clubSlug,
        onError: () => updateJoinedState(club.clubSlug, state),
        onSuccess: () => {
          similarClubsMutate();
          updateLoading(club.clubSlug, false);
          updateJoinedState(club.clubSlug, 'newlyJoined');
        },
      });
      return;
    }

    actions.join({
      clubSlug: club.clubSlug,
      onError: () => updateJoinedState(club.clubSlug, state),
      onSuccess: () => {
        similarClubsMutate();
        updateLoading(club.clubSlug, false);
        updateJoinedState(club.clubSlug, 'newlyJoined');
        setTimeout(() => {
          updateJoinedState(club.clubSlug, 'joined');
        }, 1000);
      },
    });
  };

  const isActive = (club: ClubItem) => {
    return currentMenu === 'club' && currentClub?.clubSlug === club.clubSlug;
  };

  return (
    <Box sx={styles.root} ref={ref}>
      {similarClubs.length > 0 && <Box sx={styles.background} />}
      {children}
      <Box
        sx={[
          styles.clubs,
          similarClubs.length > 0 && styles.clubsOpened(similarClubs.length),
        ]}
      >
        {similarClubs.map((item) => (
          <Fragment key={item.clubId}>
            {joinedStates[item.clubSlug] === 'joined' && (
              <NavButton
                onClick={() => handleSeeClub(item)}
                active={isActive(item)}
                tooltip={item.clubName}
              >
                <SquareAvatar
                  src={item.clubLogo || getClubIconPath(item.clubName)}
                  size={32}
                >
                  {item.clubName}
                </SquareAvatar>
              </NavButton>
            )}
            {joinedStates[item.clubSlug] !== 'joined' && (
              <MaskIcon sx={styles.notJoinedClub}>
                <SquareAvatar
                  src={item.clubLogo || getClubIconPath(item.clubName)}
                  size={32}
                  sx={styles.notJoinedClubLogo}
                  blackAndWhite
                >
                  {item.clubName}
                </SquareAvatar>
                <NotJoinedAction
                  state={joinedStates[item.clubSlug]}
                  club={item}
                  onClick={() => handleJoinClubClick(item)}
                  loading={loading[item.clubSlug]}
                />
              </MaskIcon>
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
}
