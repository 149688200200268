import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { updateBlockerOffset } from '@app/web/src/actions/layoutActions';
import useRouterHistoryValue from '@app/web/src/components/RouteHistory/useRouterHistoryValue';
import { useAppDispatch } from '@app/web/src/hooks/redux';
import useBackClick from '@app/web/src/hooks/utils/useBackClick';
import { useCommonToolbarItems } from '@app/web/src/hooks/utils/useCommonToolbarItems';
import { useUser } from '@auth0/nextjs-auth0/client';
import {
  OtherInfoSolid as OtherInfoSolidIcon,
  OtherMenu as OtherMenuIcon,
} from '@front/icon';
import {
  BaseLayoutConfig,
  Icon,
  IconButton,
  MobileTooltip,
  ResponsiveBreadcrumbs,
  TitleBar,
  useBaseLeftPanel,
} from '@front/ui';
import { useDimension, useNotifications } from '@lib/web/hooks';
import {
  CollapseLeftPanelButton,
  ResponsiveToolbar,
  useToggleLeftPanel,
} from '@lib/web/ui';

import useRouteBreadcrumbsValues from '../../breadcrumbs/useRouteBreadcrumbsValues';
import useNavMenuVisibility from '../hooks/useNavMenuVisibility';

import TopBarSearch from './TopBarSearch';

type ToolbarProps = {
  toolComponent?: ReactNode;
};

type IconProps = {
  title?: ReactNode;
  icon?: ReactNode;
};

type HeaderProps = ToolbarProps &
  IconProps & {
    backClickType?: 'back' | 'menu';
    titleSuffix?: ReactNode;
    bottomComponent?: ReactNode;
    hideCollapseLeftPanelButton?: boolean;
  };

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  iconTitle: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '24px 1fr',
    gap: 1,
  },
  titleText: {
    minWidth: 0,
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  toolbar: {
    ml: 'auto',
  },
  header: {
    display: 'flex',
  },
  titleBar: {
    flex: 1,
    minWidth: 0,
  },
};

function IconTitle({ title, icon }: IconProps) {
  return (
    <Box sx={styles.iconTitle}>
      {icon}
      <Box sx={styles.titleText}>{title}</Box>
    </Box>
  );
}

function Toolbar({ toolComponent }: ToolbarProps) {
  const { user } = useUser();
  const [showTooltip, setShowTooltip] = useState(false);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const commonToolbarItems = useCommonToolbarItems();
  const breadcrumbsValues = useRouteBreadcrumbsValues();
  const lastBreadCrumbItem = breadcrumbsValues[breadcrumbsValues.length - 1];
  return (
    <Box sx={[styles.title, styles.toolbar]}>
      {mdDown && !!lastBreadCrumbItem?.tooltip && (
        <MobileTooltip
          titleIcon={
            <Icon
              name={lastBreadCrumbItem.tooltip.icon}
              width={16}
              height={16}
            />
          }
          title={lastBreadCrumbItem.tooltip.title}
          content={lastBreadCrumbItem.tooltip.content}
          open={showTooltip}
          onClose={() => setShowTooltip(false)}
        >
          <IconButton customSize={28} onClick={() => setShowTooltip(true)}>
            <OtherInfoSolidIcon />
          </IconButton>
        </MobileTooltip>
      )}

      {/* Show get help only */}
      {!toolComponent && user && (
        <ResponsiveToolbar items={commonToolbarItems} />
      )}
      {toolComponent}
    </Box>
  );
}

export default function Header({
  title,
  icon,
  backClickType,
  toolComponent,
  titleSuffix,
  bottomComponent,
  hideCollapseLeftPanelButton,
}: HeaderProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const backClick = useBackClick();
  const { disableBack } = useRouterHistoryValue();
  const backType = backClickType ?? mdUp ? 'back' : 'menu';
  const { hasNotifications } = useNotifications();
  const { t } = useTranslation();
  const boxRef = useRef();
  const { height: innerHeight } = useDimension(boxRef);
  const dispatch = useAppDispatch();
  const breadcrumbsValues = useRouteBreadcrumbsValues();
  const { leftPanelOpened, hideLeftPanel } = useBaseLeftPanel();
  const handleToggleLeftPanel = useToggleLeftPanel();
  const { menuVisible, subNavVisible } = useNavMenuVisibility();

  const handleBackClick = () => {
    if (backType === 'back') backClick();
    else {
      handleToggleLeftPanel();
    }
  };

  const customBackIcon =
    hasNotifications && backType === 'menu' ? (
      <Badge variant="dot" color="error">
        <OtherMenuIcon />
      </Badge>
    ) : undefined;

  const subMenuOpened = (lgUp || leftPanelOpened) && !hideLeftPanel;

  const getTitle = () => {
    // TODO: replace all icon & title to breadcrumbs, then we don't need this function
    if (breadcrumbsValues.length > 0) {
      return (
        <ResponsiveBreadcrumbs
          breadcrumbsValues={breadcrumbsValues}
          mobileBottomSheetTitle={t('breadcrumb::Navigation')}
        />
      );
    }
    if (icon) {
      return <IconTitle icon={icon} title={title} />;
    }
    return title;
  };

  useEffect(() => {
    dispatch(updateBlockerOffset({ top: innerHeight }));
  }, [dispatch, innerHeight]);

  useEffect(() => {
    const subNavWidth = subNavVisible ? BaseLayoutConfig.subNavWidth : 0;
    const mdUpLeftPanelWidth =
      menuVisible && !hideLeftPanel ? BaseLayoutConfig.leftPanelWidth : 0;
    const mdDownLeftPanelWidth =
      menuVisible && !hideLeftPanel && leftPanelOpened
        ? BaseLayoutConfig.leftPanelWidth + BaseLayoutConfig.navWidth
        : 0;

    dispatch(
      updateBlockerOffset({
        left: subNavWidth + (mdUp ? mdUpLeftPanelWidth : mdDownLeftPanelWidth),
      })
    );
  }, [
    dispatch,
    subMenuOpened,
    leftPanelOpened,
    subNavVisible,
    menuVisible,
    hideLeftPanel,
    mdUp,
  ]);

  useEffect(() => {
    return () => {
      dispatch(updateBlockerOffset({ top: 0, left: 0 }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box ref={boxRef}>
      <Box sx={styles.header}>
        {!hideCollapseLeftPanelButton && <CollapseLeftPanelButton />}
        <TitleBar
          sx={styles.titleBar}
          title={getTitle()}
          titleSuffix={titleSuffix}
          backIcon={backType}
          customBackIcon={customBackIcon}
          disableBack={backType === 'back' && lgUp ? disableBack : false}
          onBackClick={handleBackClick}
          toolComponent={<Toolbar toolComponent={toolComponent} />}
        />
      </Box>
      {bottomComponent}
      <TopBarSearch />
    </Box>
  );
}
