import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Emoji, { EmojiProps } from '../Emoji';

type EmojiTextProps = BoxProps & {
  text: string;
  variant?: EmojiProps['variant'];
  align?: string;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',

    '& svg': {
      width: [48, 110],
      height: [86, 200],
    },
  },
  text: { opacity: 0.5 },
};
export default function EmojiText({
  variant = 'alien',
  align,
  text,
  sx,
  ...rest
}: EmojiTextProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box sx={[styles.root, { alignItems: align }, ...sxProps]} {...rest}>
      <Emoji variant={variant} />
      <Typography sx={styles.text} variant="subtitle2" fontWeight={400}>
        {text}
      </Typography>
    </Box>
  );
}
