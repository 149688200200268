import useMainLayout from '@app/web/src/layouts/MainLayout/hooks/useMainLayout';
import { useNotifications } from '@lib/web/hooks';

export const useMenuCurrentClubGroup = () => {
  const { currentClubGroup } = useMainLayout();
  const { clubGroupNotifications } = useNotifications();
  const clubGroupNotification = clubGroupNotifications.find(
    (group) => group.id === currentClubGroup
  );

  return {
    clubGroupNotification,
  };
};
