import { useContext } from 'react';

import {
  ClubMenuVariant,
  MainLayoutContext,
  MenuVariant,
  ProfileMenuVariant,
} from '../context';

export default function useMainLayout() {
  const [value, setValue] = useContext(MainLayoutContext);

  const clearMenu = () => {
    setValue((st) => ({
      ...st,
      menuTarget: null,
      currentMenu: null,
      currentClub: null,
      currentClubGroup: null,
      clubMenuTarget: null,
      profileMenuTarget: null,
      temporaryMenuTarget: null,
    }));
  };

  const clearTemporaryMenu = () => {
    setValue((st) => ({
      ...st,
      temporaryMenuTarget: null,
    }));
  };

  const seeMenu = (target: MenuVariant) => {
    setValue((st) => ({
      ...st,
      menuTarget: target,
    }));
  };

  const seeClubMenu = (target: ClubMenuVariant | null) => {
    setValue((st) => ({
      ...st,
      clubMenuTarget: target,
    }));
  };

  const seeProfileMenu = (target: ProfileMenuVariant | null) => {
    setValue((st) => ({
      ...st,
      profileMenuTarget: target,
    }));
  };

  const seeClub = (clubSlug?: string) => {
    setValue((st) => ({
      ...st,
      menuTarget: 'club',
      currentClub: clubSlug,
    }));
  };
  const seeClubGroup = (clubGroupId?: string) => {
    setValue((st) => ({
      ...st,
      temporaryMenuTarget: 'clubGroup',
      currentClubGroup: clubGroupId,
    }));
  };
  const seeNotifications = () => {
    setValue((st) => ({
      ...st,
      temporaryMenuTarget: 'notifications',
    }));
  };

  return {
    ...value,
    seeMenu,
    seeClub,
    seeClubGroup,
    clearMenu,
    clearTemporaryMenu,
    seeClubMenu,
    seeProfileMenu,
    seeNotifications,
  };
}
