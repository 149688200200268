import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import {
  MainApps as MainAppsIcon,
  MainClubGroup as MainClubGroupIcon,
  ProfileClubAgent as ProfileClubAgentIcon,
  PropertyTypePerson as PropertyTypePersonIcon,
  TextEditorCheck as TextEditorCheckIcon,
} from '@front/icon';
import { MenuComps } from '@lib/web/ui';

export default function DiscoverMenu() {
  const { t } = useTranslation();

  usePrefetchPages([
    '/',
    '/club-group',
    '/club/my-created',
    '/club/joined',
    '/agents',
  ]);

  return (
    <MenuComps title={t('menu.Discover')}>
      <MenuComps.Section>
        <MenuComps.SubTitle title={t('menu.Clubs')} />
        <MenuComps.Button
          icon={<MainAppsIcon />}
          href="/"
          active={Router.asPath === '/'}
        >
          {t('menu.All Clubs')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<MainClubGroupIcon />}
          href="/club-group"
          active={Router.asPath === '/club-group'}
        >
          {t('menu.Club Groups')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<PropertyTypePersonIcon />}
          href="/club/my-created"
          active={Router.asPath === '/club/my-created'}
        >
          {t('menu.My Created Clubs')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TextEditorCheckIcon />}
          href="/club/joined"
          active={Router.asPath === '/club/joined'}
        >
          {t('menu.Joined Clubs')}
        </MenuComps.Button>
        <MenuComps.SubTitle title={t('menu.Agents', 'AI')} />
        <MenuComps.Button icon={<ProfileClubAgentIcon />} href="/agents">
          {t('menu.Agents')}
        </MenuComps.Button>
      </MenuComps.Section>
    </MenuComps>
  );
}
