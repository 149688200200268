import { useIaClub } from '@lib/web/hooks';

import useMenuCurrentClubSlug from './useMenuCurrentClubSlug';

export default function useMenuCurrentClub() {
  const currentClubSlug = useMenuCurrentClubSlug();
  const { club, isLoading, mutate } = useIaClub(currentClubSlug || '');

  return {
    club,
    isLoading,
    mutate,
  };
}
