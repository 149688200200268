import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Skeleton, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { getClubIconPath } from '@app/web/src/utils/clubs';
import {
  ActionDoubleLeftChevron as ActionDoubleLeftChevronIcon,
  ActionDoubleRightChevron as ActionDoubleRightChevronIcon,
  MainAnalytics as MainAnalyticsIcon,
  OtherAddFriend as OtherAddFriendIcon,
  OtherPlay as OtherPlayIcon,
  ProfileClubAgent as ProfileClubAgentIcon,
  ProfilePlaylist as ProfilePlaylistIcon,
  ProfileSetting as ProfileSettingIcon,
  TestTrophy as TestTrophyIcon,
  ThreadsThreadView as ThreadsThreadViewIcon,
} from '@front/icon';
import { Icon, Scrollbar, SquareAvatar, useBaseLayout } from '@front/ui';
import {
  useClubSlug,
  useCurrentIaClub,
  useNotifications,
} from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';

import getAnalyticsPageConfig from '../../../pageConfig/analytics';
import { ClubMenuVariant } from '../../context';
import useCurrentMenu from '../../hooks/useCurrentMenu';
import useMainLayout from '../../hooks/useMainLayout';
import useMenuCurrentClub from '../../hooks/useMenuCurrentClub';
import useMenuCurrentClubSlug from '../../hooks/useMenuCurrentClubSlug';
import NavActions from '../NavActions';
import SubNavButton from '../NavButtons/SubNavButton';

const styles = {
  nav: {
    height: '100%',
    position: 'relative',
    bgcolor: '#151515',
    borderRight: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  scrollWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    py: '12px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },

  option: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  closeNavButton: {
    '& .menu-icon': {
      '& > svg': {
        display: 'none',
      },
    },
    '&:hover': {
      '& .menu-icon': {
        '& > svg': {
          display: 'block',
        },
        '& .MuiBox-root': {
          display: 'none',
        },
      },
    },
  },
};

function ClubIcon() {
  const { club } = useMenuCurrentClub();
  const { clubGroupNotifications } = useNotifications();
  if (!club) {
    return (
      <SquareAvatar size={32}>
        <Skeleton width={32} height={32} variant="rectangular" />
      </SquareAvatar>
    );
  }

  const clubGroupNotification = clubGroupNotifications.find((group) =>
    group.clubs.some((c) => c.slug === club?.clubSlug)
  );

  const clubIconPath = club?.clubLogo || getClubIconPath(club?.clubName || '');

  return (
    <SquareAvatar
      src={clubIconPath}
      showStacked={!!clubGroupNotification}
      size={32}
    >
      {club?.clubName}
    </SquareAvatar>
  );
}

export default function ClubNav() {
  const { t } = useTranslation();
  const clubSlug = useClubSlug();
  const menuClubSlug = useMenuCurrentClubSlug();
  const { club } = useCurrentIaClub();
  const { mainNavOpened, closeMainNav, openMainNav, openRightPanel } =
    useBaseLayout();
  const { seeClubMenu } = useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const pageConfig = getAnalyticsPageConfig(t);
  const { currentClubMenu } = useCurrentMenu();
  const { unreadChannels } = useThread();

  const { views } = useClubThreadViews({
    clubId: club?.clubId,
  });

  const hasUnreadChannelsForThisClub = useMemo(
    () =>
      unreadChannels.some((channel) => channel.data?.clubId === club?.clubId),
    [unreadChannels, club?.clubId]
  );

  const handleSeeMenu = (target: ClubMenuVariant, href: string) => {
    if (
      mdUp ||
      target === 'practice' ||
      target === 'rankings' ||
      target === 'summary'
    ) {
      Router.push(href);
    }
    seeClubMenu(target);
  };

  const isActive = (menu: ClubMenuVariant | null) => {
    return clubSlug === menuClubSlug && menu === currentClubMenu;
  };

  const { hasEditAuth } = useMemberClubRole();

  const handleAddFriendsClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalAddFriends);
  };

  usePrefetchPages([
    '/club/[slug]/start',
    '/club/[slug]/rankings',
    '/club/[slug]/summary',
    '/club/[slug]/playlists/history',
    '/club/[slug]/agents',
    '/club/form/[clubSlug]/overview',
    '/club/form/[clubSlug]/members',
  ]);

  const allThreadView =
    views && views.length > 0 ? views[views.length - 1] : false;
  const viewHref = allThreadView
    ? `/club/${clubSlug}/thread/${allThreadView.id}`
    : '';

  return (
    <>
      <Box sx={styles.nav}>
        <Box sx={styles.scrollWrapper}>
          <Scrollbar sx={styles.scroll}>
            <Box sx={styles.content}>
              {mainNavOpened && (
                <SubNavButton
                  onClick={closeMainNav}
                  tooltip={t('menu.Hide outer navigation')}
                >
                  <ActionDoubleLeftChevronIcon />
                </SubNavButton>
              )}
              {!mainNavOpened &&
                (mdUp ? (
                  <SubNavButton
                    sx={styles.closeNavButton}
                    onClick={openMainNav}
                    tooltip={t('menu.Show outer navigation')}
                  >
                    <ActionDoubleRightChevronIcon />
                    {mdUp && <ClubIcon />}
                  </SubNavButton>
                ) : (
                  <ClubIcon />
                ))}
              <SubNavButton
                onClick={() =>
                  handleSeeMenu('summary', `/club/${menuClubSlug}/summary`)
                }
                active={isActive('summary')}
                tooltip={pageConfig.summary.title}
                title={pageConfig.summary.title}
              >
                <Icon name={pageConfig.summary.icon} />
              </SubNavButton>
              <SubNavButton
                onClick={() =>
                  handleSeeMenu('practice', `/club/${menuClubSlug}/start`)
                }
                active={isActive('practice')}
                tooltip={t('menu.Practice')}
                title={t('menu.Practice')}
              >
                <OtherPlayIcon />
              </SubNavButton>
              <SubNavButton
                onClick={() => handleSeeMenu('threads', viewHref)}
                active={isActive('threads')}
                tooltip={t('menu.Chat')}
                title={t('menu.Chat')}
                disabled={!viewHref}
                hasNotification={hasUnreadChannelsForThisClub}
              >
                <ThreadsThreadViewIcon />
              </SubNavButton>
              <SubNavButton
                onClick={() =>
                  handleSeeMenu('rankings', `/club/${menuClubSlug}/rankings`)
                }
                active={isActive('rankings')}
                tooltip={t('menu.Rankings')}
                title={t('menu.Rankings')}
              >
                <TestTrophyIcon />
              </SubNavButton>

              <SubNavButton
                onClick={() =>
                  handleSeeMenu(
                    'analytics',
                    `/club/${menuClubSlug}/analytics/my-path`
                  )
                }
                active={isActive('analytics')}
                tooltip={t('menu.Analytics')}
                title={t('menu.Analytics')}
              >
                <MainAnalyticsIcon />
              </SubNavButton>
              <SubNavButton
                onClick={() =>
                  handleSeeMenu(
                    'playlists',
                    `/club/${menuClubSlug}/playlists/history`
                  )
                }
                active={isActive('playlists')}
                tooltip={t('menu.Sessions')}
                title={t('menu.Sessions')}
              >
                <ProfilePlaylistIcon />
              </SubNavButton>
              <SubNavButton
                onClick={() =>
                  handleSeeMenu('agents', `/club/${menuClubSlug}/agents`)
                }
                active={isActive('agents')}
                tooltip={t('menu.Agents')}
                title={t('menu.Agents')}
              >
                <ProfileClubAgentIcon />
              </SubNavButton>
              <SubNavButton
                onClick={handleAddFriendsClick}
                tooltip={t('menu.Add Friends')}
                title={t('menu.Add')}
              >
                <OtherAddFriendIcon />
              </SubNavButton>
              <SubNavButton
                onClick={() =>
                  Router.push(
                    `/club/form/${club?.clubSlug}/${
                      hasEditAuth ? 'overview' : 'members'
                    }`
                  )
                }
                tooltip={t('menu.Settings')}
                title={t('menu.Settings')}
              >
                <ProfileSettingIcon />
              </SubNavButton>
            </Box>
          </Scrollbar>
        </Box>
        {!mainNavOpened && <NavActions showCloseNavButton />}
      </Box>
    </>
  );
}
