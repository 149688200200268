import useCurrentMenu from '../../hooks/useCurrentMenu';

import ClubNav from './ClubNav';
import ProfileNav from './ProfileNav';

export default function SubNav() {
  const { currentMenu } = useCurrentMenu();

  if (currentMenu === 'club') return <ClubNav />;
  if (currentMenu === 'profile') return <ProfileNav />;
  return null;
}
