import { MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Router, { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import useClaimSignUpUrl from '@app/web/src/hooks/utils/useClaimSignUpUrl';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import { GuestContext } from '@front/helper';
import {
  ActionDoubleLeftChevron as ActionDoubleLeftChevronIcon,
  ActionDoubleRightChevron as ActionDoubleRightChevronIcon,
  CryptoCurrencyAha as CryptoCurrencyAhaIcon,
  MainProfileSolid as MainProfileSolidIcon,
  MainWallet as MainWalletIcon,
  OtherAddFriend as OtherAddFriendIcon,
  OtherDiscountNoOuter as OtherDiscountNoOuterIcon,
  ProfileAwards as ProfileAwardsIcon,
  ProfileLogout as ProfileLogoutIcon,
  ProfileNFTs as ProfileNFTsIcon,
  ProfileSetting as ProfileSettingIcon,
} from '@front/icon';
import { popup, Scrollbar, useBaseLayout } from '@front/ui';
import { useAuth } from '@lib/web/apis';
import { trackEvent } from '@lib/web/utils';

import { ProfileMenuVariant } from '../../context';
import useCurrentMenu from '../../hooks/useCurrentMenu';
import useMainLayout from '../../hooks/useMainLayout';
import NavActions from '../NavActions';
import SubNavButton from '../NavButtons/SubNavButton';

const styles = {
  nav: {
    height: '100%',
    position: 'relative',
    bgcolor: '#151515',
    borderRight: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  scrollWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    py: '12px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
};

export default function ProfileNav() {
  const { t } = useTranslation();
  const router = useRouter();
  const { mainNavOpened, openMainNav, closeMainNav } = useBaseLayout();
  const { seeProfileMenu } = useMainLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { currentProfileMenu } = useCurrentMenu();
  const { member, isGuest } = useAuth();
  const { logout: guestLogout } = useContext(GuestContext);
  const signUpUrl = useClaimSignUpUrl();

  usePrefetchPages([
    '/profile/account',
    '/profile/awards',
    '/profile/my-plan',
    '/profile/invite-friends',
    '/profile/wallet',
    '/profile/discount-code',
    '/marketplace/[status]',
    '/profile/settings',
  ]);

  const handleSeeMenu = (target: ProfileMenuVariant, href: string) => {
    if (mdUp || target !== 'avatars') {
      Router.push(href);
    }
    seeProfileMenu(target);
  };

  const logout = async (ev: MouseEvent) => {
    await popup.promise({
      content: isGuest
        ? t(
            'profile::logout.guest.tip',
            'You will lose this account if you log out. Are you sure you want to log out?'
          )
        : t('profile::logout.default.tip', 'Are you sure you want to log out?'),
      anchorEl: ev.currentTarget,
      popupOptions: {
        sx: {
          ml: -1,
        },
      },
      cancelText: isGuest
        ? t('profile::logout.default.cta', 'Log Out')
        : undefined,
      confirmText: isGuest
        ? t('profile::logout.guest.cta', 'Sign Up')
        : t('profile::logout.default.cta', 'Log Out'),
      popupType: 'warning',
      onCancel: (cancelType) => {
        if (cancelType === 'button') {
          router.push('/api/auth/logout');
          guestLogout();
        }
      },
      onConfirm: () => {
        if (isGuest) {
          return router.push(signUpUrl);
        }
        return router.push('/api/auth/logout');
      },
    });
  };

  return (
    <Box sx={styles.nav}>
      <Box sx={styles.scrollWrapper}>
        <Scrollbar sx={styles.scroll}>
          <Box sx={styles.content}>
            {mainNavOpened ? (
              <SubNavButton
                onClick={closeMainNav}
                tooltip={t('menu.Hide outer navigation')}
              >
                <ActionDoubleLeftChevronIcon />
              </SubNavButton>
            ) : (
              <SubNavButton
                onClick={openMainNav}
                tooltip={t('menu.Show outer navigation')}
              >
                <ActionDoubleRightChevronIcon />
              </SubNavButton>
            )}

            <SubNavButton
              onClick={() => handleSeeMenu('profile', `/profile/account`)}
              active={currentProfileMenu === 'profile'}
              tooltip={t('menu.Profile')}
              title="Profile"
              hasNotification={member?.emailVerified === false}
            >
              <MainProfileSolidIcon />
            </SubNavButton>
            <SubNavButton
              onClick={() => handleSeeMenu('awards', `/profile/awards`)}
              active={currentProfileMenu === 'awards'}
              tooltip={t('menu.Awards')}
              title="Awards"
            >
              <ProfileAwardsIcon />
            </SubNavButton>
            <SubNavButton
              onClick={() => {
                handleSeeMenu('subscribe', `/profile/my-plan`);
                trackEvent('click', { elementName: 'nav-subscription-button' });
              }}
              active={currentProfileMenu === 'subscribe'}
              tooltip={t('menu.Subscribe')}
              title="Subscribe"
            >
              <CryptoCurrencyAhaIcon />
            </SubNavButton>
            <SubNavButton
              onClick={() => handleSeeMenu('invite', `/profile/invite-friends`)}
              active={currentProfileMenu === 'invite'}
              tooltip={t('menu.Invite Friends')}
              title="Invite"
            >
              <OtherAddFriendIcon />
            </SubNavButton>
            <SubNavButton
              onClick={() => handleSeeMenu('wallet', `/profile/wallet`)}
              active={currentProfileMenu === 'wallet'}
              tooltip={t('menu.Wallet')}
              title="Wallet"
            >
              <MainWalletIcon />
            </SubNavButton>
            <SubNavButton
              onClick={() =>
                handleSeeMenu('discount', `/profile/discount-code`)
              }
              active={currentProfileMenu === 'discount'}
              tooltip={t('menu.Discount Code')}
              title="Discount"
            >
              <OtherDiscountNoOuterIcon />
            </SubNavButton>
            <SubNavButton
              onClick={() => handleSeeMenu('avatars', `/marketplace/available`)}
              active={currentProfileMenu === 'avatars'}
              tooltip={t('menu.Avatar Marketplace')}
              title="Avatars"
            >
              <ProfileNFTsIcon />
            </SubNavButton>
            {/* <SubNavButton
              onClick={() =>
                handleSeeMenu('phone', `/profile/parent-phone-number`)
              }
              active={currentProfileMenu === 'phone'}
              tooltip={t('menu.Parents Phone Number')}
              title="Phone"
            >
              <OtherPhoneIcon />
            </SubNavButton> */}
            <SubNavButton
              onClick={() => handleSeeMenu('settings', `/profile/settings`)}
              active={currentProfileMenu === 'settings'}
              tooltip={t('menu.Settings')}
              title="Settings"
            >
              <ProfileSettingIcon />
            </SubNavButton>
            <SubNavButton title="Log out" onClick={logout}>
              <ProfileLogoutIcon />
            </SubNavButton>
          </Box>
        </Scrollbar>
      </Box>
      {!mainNavOpened && <NavActions profileDisabled />}
    </Box>
  );
}
