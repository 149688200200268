import { ProfileMenuVariant } from '../../../../context';
import useCurrentMenu from '../../../../hooks/useCurrentMenu';

import AvatarsMenu from './AvatarsMenu';
import DiscountCodeMenu from './DiscountCodeMenu';

const getVariantMenu = (variant: ProfileMenuVariant | null) => {
  switch (variant) {
    case 'avatars':
      return <AvatarsMenu />;
    case 'discount':
      return <DiscountCodeMenu />;

    default:
      return null;
  }
};

export default function DynamicProfileMenu() {
  const { currentProfileMenu } = useCurrentMenu();

  return getVariantMenu(currentProfileMenu);
}
