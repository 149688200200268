import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import ThreadMemberTitle from '@lib/web/thread/ThreadMemberTitle';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import {
  isChannelAncestorInTheSameFilter,
  isChannelInTheSameFilter,
} from '@lib/web/thread/utils/channelUtils';
import { MenuComps } from '@lib/web/ui';

import MenuThreadChannels from './MenuThreadChannels';

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  titleText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  unreadDot: {
    position: 'absolute',
    top: 0,
    right: '36px',
    '& circle': {
      r: 6, // radius 6 will make it 8px (from design)
    },
    '& svg': {
      circle: {
        fill: (theme: Theme) => theme.palette.error.dark,
      },
    },
  },
  unreadViewTitle: {
    fontWeight: 700,
  },
};

export type DmMenuThreadViewProps = {
  view: GetThreadViewRes;
  scrollRef: RefObject<HTMLDivElement>;
};

export default function DmMenuThreadView({
  view,
  scrollRef,
}: DmMenuThreadViewProps) {
  const { query } = useRouter();
  const { unreadChannels, chatClient } = useThread();
  const { viewName, channelFilters, viewMemberIds, viewNameMembers } =
    useThreadViewDetail(view);

  const ref = useRef<{
    open: () => void;
    close: () => void;
  }>(null);

  useEffect(() => {
    if (query.viewId === view.id) {
      // XXX: use setTimeout to wait for component stable, otherwise the open state will be cleared
      setTimeout(() => {
        ref.current?.open();
      });
    }
  }, [query.viewId, view.id]);

  const viewHasUnreadChannels = useMemo(
    () =>
      unreadChannels?.some(
        (channel) =>
          isChannelInTheSameFilter(channel, channelFilters) ||
          isChannelAncestorInTheSameFilter(chatClient, channel, channelFilters)
      ),
    [unreadChannels, channelFilters, chatClient]
  );

  /**
   * XXX: ideally it should not happen, because every dm view should have at least 1 member (which is self)
   * It might comes from some edge case which we don't know how to reproduce for now
   */
  if (viewMemberIds.length === 0) return;

  return (
    <MenuComps.Section>
      <MenuComps.Group
        title={
          <Box sx={styles.title}>
            {viewNameMembers?.length ? (
              <ThreadMemberTitle
                members={viewNameMembers}
                typographyVariant="body2"
                badgeSize={16}
              />
            ) : (
              <Box sx={styles.titleText}>
                {viewHasUnreadChannels ? (
                  <Typography sx={styles.unreadViewTitle}>
                    {viewName}
                  </Typography>
                ) : (
                  viewName
                )}
              </Box>
            )}
          </Box>
        }
        icon={<ThreadViewIcon view={view} size="sm" />}
        ref={ref}
        href={`/direct-messages/view/${view.id}`}
        extraComponent={
          <>
            {viewHasUnreadChannels && (
              <Box component="span" sx={styles.unreadDot}>
                <ChatNotificationDotIcon />
              </Box>
            )}
          </>
        }
      >
        <MenuThreadChannels
          view={view}
          channelFilters={channelFilters}
          scrollRef={scrollRef}
          getChannelHref={({ viewId, channelCid }) =>
            `/direct-messages/channel/${channelCid}?viewId=${viewId}`
          }
          getIsActive={({ channelCid }) => channelCid === query.cid}
        />
      </MenuComps.Group>
    </MenuComps.Section>
  );
}
