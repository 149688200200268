import { useTranslation } from 'react-i18next';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import {
  OtherDiscountNoOuter as OtherDiscountNoOuterIcon,
  PropertyTypeTag as PropertyTypeTagIcon,
} from '@front/icon';
import { Role, useAuth } from '@lib/web/apis';
import { MenuComps } from '@lib/web/ui';

export default function DiscountCodeMenu() {
  const { t } = useTranslation();
  const { hasRoles } = useAuth();

  usePrefetchPages([
    '/profile/discount-code',
    '/profile/discount-code/management',
  ]);

  return (
    <MenuComps title={t('menu.Discount Code', 'Discount Code')}>
      <MenuComps.Section>
        <MenuComps.Button
          icon={<OtherDiscountNoOuterIcon />}
          href="/profile/discount-code"
        >
          {t('menu.Discount Code')}
        </MenuComps.Button>
        {hasRoles([Role.Editor, Role.Marketing]) && (
          <MenuComps.Button
            icon={<PropertyTypeTagIcon />}
            href="/profile/discount-code/management"
          >
            {t('menu.Manage Discount Codes', 'Manage Discount Codes')}
          </MenuComps.Button>
        )}
      </MenuComps.Section>
    </MenuComps>
  );
}
