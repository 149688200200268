import { useEffect, useRef } from 'react';
import Router from 'next/router';
import { useBaseLayout } from '@front/ui';

import { MenuVariant, TemporaryMenuVariant } from '../../context';
import useCurrentMenu from '../../hooks/useCurrentMenu';
import useMainLayout from '../../hooks/useMainLayout';

import DynamicClubMenu from './components/ClubMenu/DynamicClubMenu';
import DynamicProfileMenu from './components/ProfileMenu/DynamicProfileMenu';
import ClubGroupMenu from './ClubGroupMenu';
import DirectMessagesMenu from './DirectMessagesMenu';
import DiscoverMenu from './DiscoverMenu';
import InternalMenu from './InternalMenu';
import MarketplaceMenu from './MarketplaceMenu';
import NotificationMenu from './NotificationMenu';

const getVariantMenu = (variant: MenuVariant | null) => {
  switch (variant) {
    case 'discover':
      return <DiscoverMenu />;
    case 'club':
      return <DynamicClubMenu />;
    case 'profile':
      return <DynamicProfileMenu />;
    case 'directMessages':
      return <DirectMessagesMenu />;
    case 'marketplace':
      return <MarketplaceMenu />;
    case 'internal':
      return <InternalMenu />;

    default:
      return null;
  }
};

const getVariantTemporaryMenu = (variant: TemporaryMenuVariant | null) => {
  switch (variant) {
    case 'clubGroup':
      return <ClubGroupMenu />;
    case 'notifications':
      return <NotificationMenu />;
    default:
      return null;
  }
};

export default function Menu() {
  const { clearMenu, clearTemporaryMenu, temporaryMenuTarget } =
    useMainLayout();
  const { leftPanelOpened } = useBaseLayout();
  const { currentMenu } = useCurrentMenu();

  const prevUrlRef = useRef<string>();

  useEffect(() => {
    // When closing the left panel, clear the menu settings (for mobile)
    // Cannot be added to closeMenu for setting, because some leftPanelOpened is controlled by avlutils/ui-core

    if (!leftPanelOpened) {
      clearMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftPanelOpened]);

  useEffect(() => {
    const beforeHistoryChange = (ev: string) => {
      const routeUrl = ev.split('?')[0];
      if (routeUrl === prevUrlRef.current) return;
      prevUrlRef.current = routeUrl;

      clearTemporaryMenu();
    };

    Router.events.on('beforeHistoryChange', beforeHistoryChange);

    return () => {
      Router.events.off('beforeHistoryChange', beforeHistoryChange);
    };
  }, [clearTemporaryMenu]);

  return (
    getVariantTemporaryMenu(temporaryMenuTarget) || getVariantMenu(currentMenu)
  );
}
