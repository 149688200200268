import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import NotificationPanel from '@app/web/src/widgets/NotificationPage/NotificationPanel';
import {
  MainNotificationOn as MainNotificationOnIcon,
  OtherMenu as OtherMenuIcon,
} from '@front/icon';
import { IconButton } from '@front/ui';
import { useUnreadNotificationClubs } from '@lib/web/apis';
import { MenuComps } from '@lib/web/ui';

import useMainLayout from '../../hooks/useMainLayout';

const styles = {
  root: {
    '& .menu-title-bar': {
      px: '20px',
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  title: {
    fontWeight: 500,
    typography: {
      xs: 'body1',
      md: 'body2',
    },
  },
  redDot: {
    '& .MuiBadge-badge': {
      minWidth: '6px',
      height: '6px',
    },
  },
};

export default function NotificationMenu() {
  const { t } = useTranslation();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { clearTemporaryMenu } = useMainLayout();
  const scrollRef = useRef<HTMLDivElement>(null);

  const { data } = useUnreadNotificationClubs();
  const hasNewNotification = data?.data.some((club) => club.hasNewNotification);

  const handleCollapse = () => {
    clearTemporaryMenu();
  };

  return (
    <MenuComps
      title={
        <Box sx={styles.titleBox}>
          {!mdUp && (
            <>
              <IconButton
                customSize={28}
                onClick={handleCollapse}
                sx={{ mr: '10px' }}
              >
                <OtherMenuIcon width={20} height={20} />
              </IconButton>
              <Badge
                variant="dot"
                color="error"
                sx={styles.redDot}
                invisible={!hasNewNotification}
              >
                <MainNotificationOnIcon width={16} height={16} />
              </Badge>
            </>
          )}
          <Typography sx={styles.title}>{t('menu.Notifications')}</Typography>
        </Box>
      }
      disabledCollapse={!mdUp}
      onCollapse={handleCollapse}
      sx={styles.root}
      scrollProps={{
        scrollableNodeProps: {
          ref: scrollRef,
        },
      }}
    >
      <NotificationPanel scrollRef={scrollRef} />
    </MenuComps>
  );
}
